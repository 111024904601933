export const MsTimeZoneIndexValues = {
    92: "(GMT)",
    85: "(GMT+00:00)",
    90: "(GMT+00:00)",
    110: "(GMT+01:00)",
    95: "(GMT+01:00)",
    105: "(GMT+01:00)",
    84: "(GMT+01:00)",
    100: "(GMT+01:00)",
    113: "(GMT+01:00)",
    129: "(GMT+02:00)",
    130: "(GMT+02:00)",
    131: "(GMT+02:00)",
    120: "(GMT+02:00)",
    115: "(GMT+02:00)",
    133: "(GMT+02:00)",
    142: "(GMT+02:00)",
    140: "(GMT+02:00)",
    125: "(GMT+02:00)",
    135: "(GMT+02:00)",
    159: "(GMT+02:00)",
    141: "(GMT+02:00)",
    158: "(GMT+03:00)",
    134: "(GMT+03:00)",
    150: "(GMT+03:00)",
    151: "(GMT+03:00)",
    145: "(GMT+03:00)",
    155: "(GMT+03:00)",
    160: "(GMT+03:30)",
    165: "(GMT+04:00)",
    176: "(GMT+04:00)",
    169: "(GMT+04:00)",
    174: "(GMT+04:00)",
    172: "(GMT+04:00)",
    173: "(GMT+04:00)",
    304: "(GMT+04:00)",
    170: "(GMT+04:00)",
    175: "(GMT+04:30)",
    185: "(GMT+05:00)",
    180: "(GMT+05:00)",
    184: "(GMT+05:00)",
    190: "(GMT+05:30)",
    200: "(GMT+05:30)",
    193: "(GMT+05:45)",
    195: "(GMT+06:00)",
    196: "(GMT+06:00)",
    197: "(GMT+06:00)",
    203: "(GMT+06:30)",
    205: "(GMT+07:00)",
    208: "(GMT+07:00)",
    209: "(GMT+07:00)",
    207: "(GMT+07:00)",
    201: "(GMT+07:00)",
    211: "(GMT+07:00)",
    210: "(GMT+08:00)",
    227: "(GMT+08:00)",
    215: "(GMT+08:00)",
    225: "(GMT+08:00)",
    220: "(GMT+08:00)",
    228: "(GMT+08:00)",
    231: "(GMT+08:45)",
    241: "(GMT+09:00)",
    235: "(GMT+09:00)",
    229: "(GMT+09:00)",
    230: "(GMT+09:00)",
    240: "(GMT+09:00)",
    250: "(GMT+09:30)",
    251: "(GMT+09:30)",
    245: "(GMT+09:30)",
    260: "(GMT+10:00)",
    255: "(GMT+10:00)",
    256: "(GMT+10:00)",
    275: "(GMT+10:00)",
    265: "(GMT+10:00)",
    266: "(GMT+10:00)",
    270: "(GMT+10:00)",
    274: "(GMT+10:30)",
    276: "(GMT+11:00)",
    279: "(GMT+11:00)",
    281: "(GMT+11:00)",
    277: "(GMT+11:00)",
    278: "(GMT+11:00)",
    280: "(GMT+11:00)",
    295: "(GMT+12:00)",
    290: "(GMT+12:00)",
    284: "(GMT+12:00)",
    285: "(GMT+12:00)",
    299: "(GMT+12:45)",
    300: "(GMT+13:00)",
    1: "(GMT+13:00)",
    80: "(GMT-01:00)",
    83: "(GMT-01:00)",
    76: "(GMT-02:00)",
    75: "(GMT-02:00)",
    77: "(GMT-03:00)",
    65: "(GMT-03:00)",
    69: "(GMT-03:00)",
    70: "(GMT-03:00)",
    73: "(GMT-03:00)",
    74: "(GMT-03:00)",
    303: "(GMT-03:00)",
    72: "(GMT-03:00)",
    71: "(GMT-03:00)",
    60: "(GMT-03:30)",
    59: "(GMT-04:00)",
    50: "(GMT-04:00)",
    47: "(GMT-04:00)",
    58: "(GMT-04:00)",
    55: "(GMT-04:00)",
    56: "(GMT-04:00)",
    45: "(GMT-05:00)",
    301: "(GMT-05:00)",
    35: "(GMT-05:00)",
    43: "(GMT-05:00)",
    44: "(GMT-05:00)",
    40: "(GMT-05:00)",
    51: "(GMT-05:00)",
    33: "(GMT-06:00)",
    20: "(GMT-06:00)",
    34: "(GMT-06:00)",
    29: "(GMT-06:00)",
    30: "(GMT-06:00)",
    25: "(GMT-06:00)",
    15: "(GMT-07:00)",
    12: "(GMT-07:00)",
    13: "(GMT-07:00)",
    10: "(GMT-07:00)",
    5: "(GMT-08:00)",
    11: "(GMT-08:00)",
    4: "(GMT-08:00)",
    3: "(GMT-09:00)",
    9: "(GMT-09:00)",
    8: "(GMT-09:30)",
    7: "(GMT-10:00)",
    2: "(GMT-10:00)",
    6: "(GMT-11:00)",
    0: "(GMT-12:00)",
    302: "(UTC+02:00)"
}